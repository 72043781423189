<template>
	<div>
		<v-card class="elevation-6 card-login" :loading="loading">
			<v-card-text class="pa-0">
				<v-row>
					<v-col cols="12" class="px-11 py-5">
						<v-row>
							<v-row justify="center" class="text-center">
								<v-col cols="4" class="d-felx justify-center">
								  <v-img
						        contain
						        max-width="150"
						        :src="require('@/assets/Todo-carta-logo.png')"
						      ></v-img>
								</v-col>
							</v-row>
							<v-col cols="12" class="pt-0 px-10">
								<h3 class="primary--text py-1" v-text="'Iniciar Sesión'"></h3>
								<base-divider color="orange" dense align="left" max-width="35" space="2" />
								<span class="font-weight-light blue-grey--text" style="font-size: 1rem">Ingrese sus datos para administrar todos los servicios y explorar nuestras herramientas</span>
							</v-col>
						</v-row>
						<v-row>
							<v-col cols="12" class="px-10 pb-0">
								<validation-observer ref="observerLogin">
									<v-form>
										<validation-provider name="Usuario" rules="required" v-slot="{ errors }">
											<v-text-field v-model="form.username" outlined label="Usuario" name="Usuario" prepend-inner-icon="mdi-account-circle" type="text" class="py-2" :error-messages="errors[0]" clearable :disabled="loading" @keyup.enter="login"/>
											</v-text-field>
										</validation-provider>
										<validation-provider name="Contraseña" rules="required" v-slot="{ errors }">
											<v-text-field v-model="form.password" outlined id="password" label="Contraseña" name="Contraseña" prepend-inner-icon="mdi-lock" :type="show ? 'text' : 'password'" :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'" :error-messages="errors[0]" :disabled="loading" @keyup.enter="login" @click:append="show = !show" class="pb-0"/></v-text-field>
										</validation-provider>
									</v-form>
								</validation-observer>
							</v-col>
						</v-row>
						<v-row justify="center" class="text-right">
							<v-col cols="12" class="px-10 pt-0">
								<a href="//"
									class="primary--text font-italy"
									style="text-decoration: none"
									@click.prevent="$router.push({name: 'forget'})">¿Olvido su contraseña?</a>
							</v-col>
						</v-row>
						<v-row justify="center" class="text-center">
							<v-col cols="8" class="px-10">
								<v-btn block color="primary" @click="login" :loading="loading">Autenticar</v-btn>
							</v-col>
						</v-row>
<!-- 						<v-row justify="center" class="text-center">
							<v-col cols="12" class="px-11 d-flex justify-center">
								<v-btn text color="blue" @click="$router.push({name: 'register'})" >Registrar Restaurant</v-btn>
							</v-col>
						</v-row> -->
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
		<base-material-snackbar
	      v-model="snackbar.active"
	      type="error"
	      bottom
	      center
	    >
	    	{{ snackbar.text }}
	    </base-material-snackbar>
	</div>


</template>
<script>
export default {
	data() {
		return {
			form:{
				username: '',
				password: ''
			},
			loading: false,
			snackbar:{
				text:'',
				active: false
			},
			show: false,
		}
	},
	created(){
		this.$store.commit('SET_OVERLAY', false);
	},
	methods: {
		login(){
			this.$refs.observerLogin.validate().then(result =>{
				if(result){
					this.loading = true;
					this.$store.dispatch('auth/login',this.form).then(response => {
						// this.$store.commit('SET_OVERLAY', true);
						this.$router.push({ path: '/tc-admin/dashboard'});
	        		}).catch(e => {
						this.snackbar = {
							active: true,
							text: e.response.data.message
						};
						this.loading = false;
	        		});
				}
			});
		},
	},
}
</script>

